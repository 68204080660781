import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1279.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" >


<path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
<path d="M5369 7163 c0 -10 -3 -13 -5 -6 -6 14 -70 -49 -124 -122 -47 -64
-121 -219 -135 -283 l-7 -32 55 53 c30 30 72 67 93 83 22 16 41 32 44 35 3 3
30 21 60 38 30 18 45 30 33 26 -19 -5 -22 -3 -16 12 7 17 6 17 -11 1 -22 -23
-26 -23 -26 2 0 13 10 24 25 30 16 6 23 14 19 25 -3 8 -1 15 5 15 8 0 11 12 8
33 -2 17 -9 31 -15 30 -7 -2 -9 4 -5 14 5 11 3 14 -6 9 -7 -5 -11 -16 -8 -26
2 -9 -4 -28 -14 -41 -15 -20 -19 -21 -19 -6 0 9 5 17 11 17 7 0 10 12 7 28 -5
34 8 48 40 44 12 -2 22 1 22 7 0 6 -5 11 -11 11 -5 0 -7 5 -4 10 3 6 1 10 -4
10 -6 0 -11 -8 -12 -17z m-51 -73 c-7 -11 -17 -20 -22 -20 -5 0 -15 -8 -23
-17 -9 -11 -11 -12 -7 -3 9 19 47 58 57 59 4 1 2 -8 -5 -19z m12 -154 c0 -2
-7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z"/>
<path d="M5415 7170 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5410 7130 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M5450 7020 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z"/>
<path d="M5385 6820 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M5344 6799 c-5 -8 -3 -9 6 -4 10 6 12 4 7 -9 -4 -10 -7 -20 -7 -22 0
-2 -7 -4 -16 -4 -9 0 -12 5 -8 11 4 7 1 10 -7 7 -8 -3 -13 -11 -11 -18 2 -7
-3 -10 -11 -7 -18 7 -157 -108 -157 -130 0 -7 31 17 48 36 7 9 12 10 12 3 0
-8 9 -7 33 2 17 8 58 20 91 27 44 9 54 15 40 20 -11 4 -13 8 -6 8 6 1 12 8 12
17 0 14 -1 14 -16 0 -8 -9 -20 -13 -26 -10 -6 4 1 13 16 23 26 15 35 45 17 56
-5 3 -13 0 -17 -6z m-6 -94 c-2 -1 -19 -5 -38 -9 -31 -6 -32 -5 -15 7 10 8 27
12 37 9 10 -2 17 -6 16 -7z"/>
<path d="M5398 6713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5272 6573 c-70 -28 -68 -43 4 -43 23 0 61 -7 84 -15 24 -8 45 -13
48 -11 2 3 -9 8 -24 13 l-29 7 30 7 30 7 -29 1 c-16 0 -26 4 -23 7 3 4 2 14
-3 23 -7 15 -4 17 23 13 43 -6 31 4 -13 11 -25 4 -53 -1 -98 -20z m79 -20
c-11 -5 -11 -7 0 -15 10 -7 7 -8 -11 -4 -14 3 -38 6 -55 7 -23 2 -20 4 15 9
58 9 68 10 51 3z"/>
<path d="M4330 5528 c0 -334 4 -567 10 -589 5 -19 23 -48 40 -64 25 -24 39
-29 105 -33 l75 -5 0 222 c0 121 3 221 8 221 4 0 18 -17 32 -37 54 -82 221
-282 279 -336 69 -64 119 -83 190 -72 32 5 54 16 78 40 45 45 43 67 -8 91 -23
10 -76 52 -118 94 -79 77 -231 260 -231 277 0 11 140 181 244 297 l71 78 13
-48 c66 -244 203 -584 292 -728 l20 -33 -33 -64 c-17 -35 -44 -72 -59 -82 -22
-16 -110 -33 -195 -36 -20 -1 -15 -72 7 -108 36 -59 117 -83 213 -63 103 21
180 107 282 315 121 248 285 702 285 790 0 38 -5 52 -29 76 -27 27 -35 29
-104 29 -85 0 -77 8 -92 -100 -20 -141 -139 -520 -164 -520 -13 0 -97 187
-129 290 -16 52 -38 131 -47 175 -33 147 -46 155 -265 155 -122 0 -170 -4
-197 -15 -47 -20 -168 -146 -258 -269 -38 -52 -73 -96 -77 -96 -5 0 -8 134 -8
299 0 293 0 299 -23 332 -31 46 -80 69 -149 69 l-58 0 0 -552z"/>
<path d="M8480 5642 c0 -412 1 -444 20 -505 69 -222 285 -351 507 -302 43 10
94 26 114 36 64 32 144 114 181 186 47 90 63 159 62 268 -1 153 -48 270 -141
355 -148 136 -343 145 -470 22 -24 -23 -43 -38 -43 -34 0 214 -6 299 -25 331
-32 55 -67 75 -140 79 l-65 4 0 -440z m534 -76 c43 -18 91 -77 107 -135 8 -25
14 -86 14 -136 -1 -72 -5 -100 -23 -140 -54 -118 -146 -161 -260 -121 -54 19
-79 42 -110 101 -22 42 -27 66 -30 156 -4 92 -1 112 18 156 23 55 57 93 105
117 37 20 134 21 179 2z"/>
<path d="M3765 6030 c-220 -36 -368 -202 -353 -395 9 -106 60 -180 158 -230
29 -15 112 -41 184 -60 139 -35 198 -59 230 -94 27 -30 19 -131 -14 -166 -63
-67 -283 -74 -405 -12 -22 11 -59 33 -82 48 -23 16 -44 29 -47 29 -3 0 -6 -26
-6 -57 0 -175 94 -252 325 -269 207 -15 380 67 452 214 25 50 28 68 28 152 0
82 -4 102 -25 144 -43 84 -127 141 -253 172 -161 39 -240 68 -275 101 -33 31
-34 35 -30 88 4 45 10 59 34 80 100 87 380 66 490 -36 11 -10 23 -19 27 -19 4
0 7 30 7 67 0 118 -48 183 -164 223 -66 22 -207 33 -281 20z"/>
<path d="M6290 5783 c-187 -31 -323 -168 -365 -368 -29 -136 -13 -248 50 -360
87 -155 268 -248 446 -231 200 20 279 81 279 216 l0 62 -32 -24 c-46 -35 -118
-57 -208 -64 -143 -12 -253 40 -294 138 -9 21 -16 40 -16 43 0 3 48 5 108 5
275 0 409 39 459 134 24 45 21 156 -6 227 -60 158 -239 253 -421 222z m134
-194 c61 -17 96 -61 96 -119 0 -81 -45 -100 -242 -100 -126 0 -128 0 -128 23
0 72 55 157 123 191 38 18 96 20 151 5z"/>
<path d="M7040 5775 c-163 -46 -260 -143 -276 -278 -18 -151 70 -231 310 -281
65 -14 129 -32 142 -41 50 -33 39 -105 -20 -134 -74 -35 -253 -18 -356 34
l-50 25 0 -68 c0 -90 18 -118 111 -166 135 -72 365 -50 473 45 59 52 85 103
93 182 7 83 -9 139 -58 192 -48 52 -109 79 -236 105 -164 33 -198 58 -176 127
29 91 267 99 406 13 l47 -29 0 65 c0 98 -38 153 -135 194 -67 29 -199 36 -275
15z"/>
<path d="M7552 5438 c3 -309 4 -325 26 -380 76 -190 311 -282 537 -211 111 36
181 108 217 227 19 64 22 95 22 316 1 220 -1 250 -18 287 -24 53 -79 83 -154
83 l-52 0 0 -273 c0 -317 -8 -371 -64 -424 -78 -73 -206 -48 -258 49 -21 40
-23 58 -28 293 -5 226 -7 253 -25 283 -26 43 -88 72 -154 72 l-52 0 3 -322z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
